import axios from 'axios';
const baseURL = `${window.location.protocol}//${window.location.hostname}:5000`;

const sendRequest = async (data, endpoint) => {
    console.log(endpoint);

    try {
        const response = await axios.post(`${baseURL}${endpoint}`, data, {withCredentials: true, mode: 'cors'});
        console.log(`respose: ${response.data}`);
        return response.data; 
    } catch (error) {
        console.error("ERROR:", error);
    }
}



export default sendRequest;
